import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../../context';
import { OrganisationTag, OrganisationTagType } from '../../../services';
import { getCssVar, IconLabel, layoutPath } from '../../../shared';

import classes from './ExternalTagsList.module.scss';

const COLLAPSED_TAGS_LIST_SIZE = 3;

export const ExternalTagsList: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  // const { channelId } = useContext(UserContext).userInfo.userData;

  // const { data: externalTags = [], isFetching } = useStoryCardsUsedTagsQuery({ channelId });

  const externalTags = useContext(OrganisationContext).organisation.tags.filter(
    ({ type }) => type === OrganisationTagType.EXTERNAL
  );

  const [tagsList, setTagsList] = useState<OrganisationTag[]>(externalTags);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!externalTags.length) {
      return;
    }

    setTagsList(collapsed ? externalTags.slice(0, COLLAPSED_TAGS_LIST_SIZE) : externalTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const collapsible = useMemo(() => {
    if (Boolean(externalTags.length <= COLLAPSED_TAGS_LIST_SIZE)) {
      return null;
    }

    return (
      <div
        className={classes['external-tags-list__collapsible']}
        onClick={() => setCollapsed(!collapsed)}
      >
        {t(`externalTagsList.show-${collapsed ? 'more' : 'less'}`)}
      </div>
    );
  }, [collapsed, externalTags.length, t]);

  // if (isFetching) {
  //   return (
  //     <div className={classes['loader']}>
  //       <Skeleton height={'1rem'} />
  //       <Skeleton height={'1rem'} />
  //       <Skeleton height={'1rem'} />
  //     </div>
  //   );
  // }

  if (!Boolean(externalTags.length)) {
    return null;
  }

  return (
    <div className={classes['external-tags-list']}>
      <div className={classes['external-tags-list__title']}>{t('externalTagsList.title')}</div>
      {tagsList.map(({ id, name }) => (
        <IconLabel
          key={id}
          label={`#${name}`}
          className={classes['external-tags-list__item']}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={() => navigate(layoutPath(`/tags/${id}`))}
          disabled={pathname === layoutPath(`/tags/${id}`)}
        />
      ))}
      {collapsible}
    </div>
  );
};
